@import "./base/preflight";
@import "./tailwind-output.css";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 14px;
}
.mt-autocomplete {
  height: auto;

  &-input {
    padding-bottom: 18px;
    &__label {
      padding-bottom: 16px;
    }

    border-width: 0;
    border-radius: 0;
    border-bottom-width: 1px;
    overflow: hidden;
    border-color: var(--metalic-silver);
    border-style: solid;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 4px;
    height: 56px;
    background: transparent;

    text-align: left;
    padding-left: 16px;
    padding-right: 10px;

    &-disabled {
      background-color: #0000000a;
    }
    // }
    &:hover,
    &:focus,
    &:active {
      border-color: var(--metalic-silver) !important;
      box-shadow: none;
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
    }

    &input::-webkit-input-placeholder {
      color: var(--secondary-color) !important;
    }

    input[readonly] {
      opacity: 0.8;
    }
    .ant-input-suffix {
      font-size: 12px;
    }
  }
}

.mt-input {
  height: 54px;
  display: flex;
  align-items: flex-end;

  input {
    &:disabled {
      opacity: 0.6;
    }
  }
}

.mt-button {
  display: flex;
  height: 56px;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  transition: all 0.3s linear;

  &:hover {
    color: #1b1f27 !important;
    border-color: #1b1f27;
    opacity: 0.8;
  }

  &.primary,
  &.primary:hover {
    background-color: #1b1f27 !important;
    color: #fcfbfc !important;
    border-color: #1b1f27 !important;
  }

  &.white,
  &.white:hover {
    background-color: #fcfbfc !important;
    color: #1b1f27 !important;
    border-color: #fcfbfc !important;
  }
  &:disabled {
    opacity: 0.6;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
