*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.visible {
  visibility: visible
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  inset: 0px
}

.left-0 {
  left: 0px
}

.top-0 {
  top: 0px
}

.-z-10 {
  z-index: -10
}

.z-10 {
  z-index: 10
}

.z-30 {
  z-index: 30
}

.z-\[1000\] {
  z-index: 1000
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-3 {
  margin-left: 0.75rem
}

.ml-4 {
  margin-left: 1rem
}

.mr-4 {
  margin-right: 1rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-9 {
  margin-top: 2.25rem
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.size-\[400px\] {
  width: 400px;
  height: 400px
}

.h-8 {
  height: 2rem
}

.h-\[100px\] {
  height: 100px
}

.h-\[34px\] {
  height: 34px
}

.h-\[64px\] {
  height: 64px
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.min-h-screen {
  min-height: 100vh
}

.w-\[100px\] {
  width: 100px
}

.w-\[360px\] {
  width: 360px
}

.w-\[90\%\] {
  width: 90%
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.max-w-\[150px\] {
  max-width: 150px
}

.max-w-\[300px\] {
  max-width: 300px
}

.max-w-\[400px\] {
  max-width: 400px
}

.flex-1 {
  flex: 1 1 0%
}

.origin-\[0\] {
  transform-origin: 0
}

.-translate-y-\[8px\] {
  --tw-translate-y: -8px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-2 {
  gap: 0.5rem
}

.gap-3 {
  gap: 0.75rem
}

.gap-4 {
  gap: 1rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-\[20px\] {
  gap: 20px
}

.gap-\[46px\] {
  gap: 46px
}

.gap-x-4 {
  column-gap: 1rem
}

.gap-y-2 {
  row-gap: 0.5rem
}

.rounded-2xl {
  border-radius: 1rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.border-0 {
  border-width: 0px
}

.border-\[1px\] {
  border-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.border-solid {
  border-style: solid
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-b-metalic-silver {
  border-bottom-color: var(--metalic-silver)
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.p-0 {
  padding: 0px
}

.p-1 {
  padding: 0.25rem
}

.p-4 {
  padding: 1rem
}

.p-6 {
  padding: 1.5rem
}

.p-\[16px\] {
  padding: 16px
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-16 {
  padding-top: 4rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem
}

.text-\[20px\] {
  font-size: 20px
}

.text-\[30px\] {
  font-size: 30px
}

.text-\[32px\] {
  font-size: 32px
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-black {
  font-weight: 900
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.capitalize {
  text-transform: capitalize
}

.leading-\[0\] {
  line-height: 0
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity))
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-primary-color {
  color: var(--primary-color)
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.text-secondary-45 {
  color: #11131873
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.opacity-0 {
  opacity: 0
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.\!ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-300 {
  transition-duration: 300ms
}

.hover\:opacity-85:hover {
  opacity: 0.85
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:translate-y-\[16px\] {
  --tw-translate-y: 16px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-\[18px\] {
  font-size: 18px
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-6 {
  line-height: 1.5rem
}

.peer:focus ~ .peer-focus\:bottom-0 {
  bottom: 0px
}

.peer:focus ~ .peer-focus\:-translate-y-\[8px\] {
  --tw-translate-y: -8px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.peer:focus ~ .peer-focus\:text-\[14px\] {
  font-size: 14px
}

.peer:focus ~ .peer-focus\:leading-\[20px\] {
  line-height: 20px
}

@media (min-width: 768px) {
  .md\:w-\[60\%\] {
    width: 60%
  }

  .md\:flex-row {
    flex-direction: row
  }
}

@media (min-width: 1024px) {
  .lg\:flex-nowrap {
    flex-wrap: nowrap
  }
}

.\[\&\>div\]\:flex-1>div {
  flex: 1 1 0%
}

.\[\&\>svg\>path\]\:fill-black>svg>path {
  fill: #000
}
